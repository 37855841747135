import React from 'react';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core';

import Layout from '../../layout/HomeLayout';

const styles = (): StyleRules =>
  createStyles({
    pdfRoot: {
      minHeight: '1092px',
      width: '1028px',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      margin: '1rem auto',
      position: 'relative',
    },
    button: {
      position: 'absolute',
      right: '1rem',
      top: '1rem',
    },
  });

const projectMap = ({ classes }: Props): React.ReactElement => {
  return (
    <div>
      <Layout>
        <div className={classes.pdfRoot}>地图</div>
      </Layout>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(projectMap);
